@media only screen and (max-width: 1280px) {
  

    .day_time {
        width: 200px;
    }


 
   
}

@media only screen and (max-width: 1200px) {

    .fs-new {
        font-size: 17px !important;
    }

    .c-text {
        line-height: 11px;
        font-size: 9px;
    }

    .flow_sec p {
        font-size: 9px;
    }

    .search_s {

        position: absolute;
        right: 10px;
    }

    

    .flow_sec h5 {

        font-size: 12px;
        margin-top: 0px;
    }

    .w-800 {
        width: 467px !important;
    }

    .gardientbackground img {
        height: 100%;
        object-fit: cover;
    }
    .gardientbackground {
        position: absolute;
        height: 100%;
    }

    .team_defolt h5 {
        font-size: 15px !important;
    }
    // .c-img2 {
    //     width: 35px;
    //     height: 35px;
    //     border-radius: 50%;
    // }

    .c-team {
        width: 35px;
        height: 35px;
    }

    .sticky {
        width: 71.7% !important;
       
    }
}

@media only screen and (max-width: 1025px) {

    .fs-new {
        font-size: 13px !important;
    }

    .navbar-new h5 {
        font-size: 13px;
    }

    .flow_sec p {
        font-size: 11px;
    }
    
    .sticky {
        width: 68.5% !important;
    }


 
    .form-container {
        width: 92%;
    }

    .form-container h5 {
        font-weight: 300;
        line-height: 27px;
    }


    .day_time {
        width: 196px;
    }

    .sec_first h5 {
        font-size: 10px;
    }

    .sign_s::before {
        width: 41px;
        height: 41px;
        z-index: 1;
        margin-top: -3px;
        margin-left: 11px;
        background-size: 86%;
    }

    .c-img_small {
        width: 26px;
        height: 26px;
    }

    .day_time {
        width: 162px;
        font-size: 11px;
    }

    .sec_first small {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 991px) {

   
    .navbar-brand {
        display: none !important;
      }

    .time_color {
        font-size: 8px !important;
      }
      .time_color_gray {
        font-size: 8px !important;
      }
      
      .time_color_white {
        font-size: 8px !important;
      }
      .time_color_white2 {
        font-size: 8px!important;
      }


    .download-files {
        margin-right: -25px !important;
       
    }
    

    .w-340  {
        width: 381px !important;
    }

    .data_name {

        width: 307px !important;

    }

    .searchTerm { 

        margin-left: 0 !important;
    }

    .search_dropdown  .profilepic {
        width: 26px !important;
        height: 26px !important;
    }

    .search_dropdown {

        left: 16px !important;
        width: 82% !important;
        height: 81px !important;

     }

     .search_dropdown p {
        font-size: 9px !important;
     }

    .search-small {
        left: 10px !important;
      }

    .c-text {
        line-height: 11px;
        font-size: 12px;
    }

    .user-status h4{
        margin-right: 4px !important;
    }

    ul.days li {
        
        width: 24% !important;
        margin-right: 2px !important;
    }

    .button-section button {
        left: 30px !important;
    }

    .left-arow {
        width: 12px;
        margin-right: 14px;
        margin-top: 10px;
        margin-left: 13px;
    }
    
    .start-sec {

        width: 331px !important;
        padding-top: 4px !important;
    }

    .flow_sec .border-start {
        height: auto !important;
    }


    .start-sec img {
        width: 34px !important;
        margin-top: -20px;
    }

    .sticky {
        width: 74.5% !important;
    }

  

    .team_defolt h5 {
        font-size: 20px !important;
    }

    .header_search {
        width: 153px;
    }

    .emv-invittion_h {
        height: 90%;
    }

    .otp_bg {
        background: none;
    }

    .otp-container {
        width: 53%;
        margin: 25px auto 0;
    }

    .c-img_small {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
    }

    .sec_first small {
        font-size: 13px !important;
    }

    .sign_s::before {
        width: 68px;
        height: 68px;
        background: url(assets/images/collab/image_back.png) no-repeat;
        content: "";
        position: absolute;
        z-index: 1;
        margin-top: -6px;
        margin-left: 9px;
        background-size: 46px

    }

    .sec_first p {
        font-size: 14px;
    }

    .day_time {
        width: 86%;
        font-size: 11px;
    }

    .sec_first {
        padding-bottom: 3px;
        
    }

    .sec_first p {
        font-size: 13px;
    }

   

    .tab {
        margin-left: 18px;
    }

    .c-img {
        width: 30px;
        height: 30px;
    }

    .f-control2 {
        font-size: 13px;
    }

    // .online-status {
    //     margin: 20px 0 0 19px;
    // }

    .modal-dialog {
        max-width: 700px !important;
    }

    .deletmodal .modal-dialog {
        max-width: 500px !important;
    }

    .w-14 {
        height: 47px;
    }

    .left_img {
        width: 100%;
    }
    /* # Before login */
    .res-stu.bg-color-dark {
        width: 100%;
    }

    .gardientbackground {
        height: 100%;
        display: none;
    }

    .modal-content.org_setup_skip_model {
        padding: 0 1rem !important;
    }

    /*Inner page*/
    .inner-sec_ {
        padding: 0 21px;
    }

    .subheader h5 {
        font-size: 21px;
    }
}

@media only screen and (max-width: 768px) {

    .sticky {
        width:72% !important;
    }

    .c-img_small {
        width: 28px;
        height: 28px;
    }

    .sign_s::before {
        width: 57px;
        height: 57px;
        position: absolute;
        z-index: 1;
        margin-top: -7px;
        margin-left: 9px;
        background-size: 42px;
    }

    .start-sec {

        width: 297px !important;
        padding-top: 4px !important;
    }

    .sec_first h5 {
        font-size: 16px;
    }

    .sec_first p {
        font-size: 13px;
    }

    .tab {
        margin-left: 18px;
        font-size: 14px;
    }

    .f-control2 {
        font-size: 12px;
    }

    .otp-container form {
        padding: 30px 25px;
    }

    button.btn.primary-xl {
        padding: 28px;
    }
}
